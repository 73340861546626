import { useReducer } from 'react'

import { Grid } from '@mui/material'

import InvoicesInfo, {
  actionsModalReducer,
  actionsModalReducerInitialState
} from '@components/Invoicing/InvoicesTabs/InvoicesInfo'
import { useAuth } from '@contexts/auth'
import { checkPerms } from '@utils/checkPerms'
import NoPermissionPage from '@components/common/NoPermissionPage'

export default function Invoices(props) {
  const { dataProps, queriesProps, operationId, leaseId } = props

  const { organization, organizationTeam, permissions } = useAuth()

  const view_invoices = checkPerms({
    perm: ['view_invoices'],
    org: organization,
    team: organizationTeam,
    project: operationId,
    perms: permissions
  })

  const [actionsModal, dispatchActionsModal] = useReducer(
    actionsModalReducer,
    actionsModalReducerInitialState
  )

  if (!view_invoices) return <NoPermissionPage />

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Stack direction="row" justifyContent="flex-end">
          <InvoicesHeader
            // view={view}
            dataProps={dataProps}
            queriesProps={queriesProps}
            actionsProps={{ actionsModal, dispatchActionsModal }}
            params={{ operationId: operationId, leaseId: leaseId }}
            // params={{ invoicesType }}
            // selectionProps={{ itemsSelected, setItemsSelected }}
          />
        </Stack>
      </Grid> */}
      <Grid item xs={12}>
        <InvoicesInfo
          // view={view}
          dataProps={dataProps}
          queriesProps={queriesProps}
          actionsProps={{ actionsModal, dispatchActionsModal }}
          params={{ operationId: operationId, leaseId: leaseId }}
        />
      </Grid>
    </Grid>
  )
}
