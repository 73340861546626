import React from 'react'
import { useRouter } from 'next/router'
import { Grid } from '@mui/material'

import Payments from '@components/Invoicing/Payments'

import API_URL from '@config/services'
import { useFetchData } from '@api/fetchData'
import { useAuth } from '@contexts/auth'
import { checkPerms } from '@utils/checkPerms'
import NoPermissionPage from '@components/common/NoPermissionPage'

export default function PaymentsTabs(props) {
  const { tenantId, leaseId } = props
  const { organization, organizationTeam, permissions } = useAuth()
  const router = useRouter()

  const { id: projectId } = router.query

  const { data } = useFetchData(API_URL.PROJECTS.GET_PROJECT_PK(projectId))

  const view_invoices = checkPerms({
    perm: ['view_invoices'],
    org: organization,
    team: organizationTeam,
    project: projectId,
    perms: permissions
  })

  if (!view_invoices) return <NoPermissionPage />

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Payments
          projectId={projectId}
          project={data}
          leaseId={leaseId}
          tenantId={tenantId}
        />
      </Grid>
    </Grid>
  )
}
