import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from '@contexts/translation'

import { Button, Grid, MenuItem, TextField } from '@mui/material'
import Select from '@components/common/Select'
import { format } from 'date-fns'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { onCreateDiscount, onUpdateDiscount } from '@api/discounts'
import API_URL from '@config/services'
import { useFetchData } from '@api/fetchData'

export default function DiscountForm(props) {
  const {
    leaseId,
    fetchDiscountsURL,
    discountId,
    duplicateId,
    discountDetails,
    handleClose
  } = props
  const { dictionary, t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)

  const currentYear = new Date().getFullYear()

  const months = [
    { value: '01', label: t('month_names.0') },
    { value: '02', label: t('month_names.1') },
    { value: '03', label: t('month_names.2') },
    { value: '04', label: t('month_names.3') },
    { value: '05', label: t('month_names.4') },
    { value: '06', label: t('month_names.5') },
    { value: '07', label: t('month_names.6') },
    { value: '08', label: t('month_names.7') },
    { value: '09', label: t('month_names.8') },
    { value: '10', label: t('month_names.9') },
    { value: '11', label: t('month_names.10') },
    { value: '12', label: t('month_names.11') }
  ]

  const yearsFormat = Array.from(
    { length: 100 },
    (_, index) => currentYear - 50 + index
  )

  const years = yearsFormat?.map(year => ({
    value: `${year}`,
    label: `${year}`
  }))

  const schema = yup.object().shape({
    title: yup.string().required(dictionary.required),
    amount: yup.string().required(dictionary.required),
    month: yup.string().required(dictionary.required),
    year: yup.string().required(dictionary.required)
  })

  const {
    control,
    formState: { errors },
    setValue,
    reset,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      month: data ? data.start_date.split('-')[1] : null,
      year: data ? data.start_date.split('-')[2] : currentYear.toString()
    }
  })

  const fetchURL = API_URL.DISCOUNTS.GET_DISCOUNT(discountId || duplicateId)
  const { data } = useFetchData(discountId || duplicateId ? fetchURL : null)

  useEffect(() => {
    if (discountDetails) {
      setValue('amount', discountDetails.amount)
      setValue('title', discountDetails.title)
    }

    if (data) {
      setValue('title', data.title)
      setValue('amount', data.amount)
      setValue('description', data.description)
      setValue('month', data.start_date.split('-')[1])
      setValue('year', data.start_date.split('-')[2])
    }
  }, [data, setValue, discountDetails])

  const onCreate = async values => {
    setLoading(true)
    const payload = {}
    payload.lease = Number(leaseId)
    payload.title = values.title
    payload.description = values.description
    payload.amount = values.amount
    payload.start_date = format(
      new Date(`${values.month}-01-${values.year}`),
      'dd-MM-yyyy'
    )
    payload.end_date = format(
      new Date(`${values.month}-25-${values.year}`),
      'dd-MM-yyyy'
    )

    const response = discountId
      ? await onUpdateDiscount(discountId, payload)
      : await onCreateDiscount(payload)

    responseHandler({
      response,
      callback: () => {
        mutate(fetchDiscountsURL)
        mutate(fetchURL)
      },
      dictionary,
      msgSuccess: discountId
        ? dictionary.discount_edited
        : dictionary.discount_created,
      snackbar: enqueueSnackbar
    })

    reset()
    setLoading(false)
    handleClose?.()
  }

  return (
    <Grid item xs={12}>
      <form
        data-cy={'form'}
        noValidate={true}
        onSubmit={handleSubmit(onCreate)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="title"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  variant="filled"
                  label={dictionary.title}
                  error={Boolean(errors.title)}
                  helperText={errors.title && errors.title.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="amount"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  label={dictionary.amount}
                  error={Boolean(errors.amount)}
                  helperText={errors.amount && errors.amount.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="description"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  label={dictionary.description}
                  error={Boolean(errors.description)}
                  helperText={errors.description && errors.description.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              fullWidth
              required
              name="month"
              label={dictionary.month}
              variant="outlined"
              control={control}
              error={Boolean(errors.month)}
              errorMessage={errors.month && errors.month.message}
            >
              {months.map(month => (
                <MenuItem key={month.value} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Select
              fullWidth
              required
              name="year"
              label={dictionary.year}
              variant="outlined"
              control={control}
              error={Boolean(errors.year)}
              errorMessage={errors.year && errors.year.message}
            >
              {years.map(year => (
                <MenuItem key={year.value} value={year.value}>
                  {year.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} align="right">
            <Button
              size="small"
              type="submit"
              disabled={loading}
              data-testid="Button-342465e9-5929-4573-8d95-e98c4be3013c"
            >
              {dictionary.submit}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}
