import { Grid, MenuItem, Stack, TextField, Typography } from '@mui/material'

import { useTranslation } from '@contexts/translation'

import { Controller } from 'react-hook-form'

export default function InvoicingRulesStep(props) {
  const { control, isCompany, errors } = props
  const { dictionary, t, lang } = useTranslation()
  const getDayString = day => {
    if (day > 10 && day < 20) {
      return `${day}${dictionary['th_day_of_the_month']}`
    }
    if (day.toString().slice(-1) == 1) {
      return `${day}${dictionary['st_day_of_the_month']}`
    }
    if (day.toString().slice(-1) == 2) {
      return `${day}${dictionary['nd_day_of_the_month']}`
    }
    if (day.toString().slice(-1) == 3) {
      return `${day}${dictionary['rd_day_of_the_month']}`
    }

    return `${day}${dictionary['th_day_of_the_month']}`
  }

  const INVOICING_METHODS = [
    { value: 'month_start', label: dictionary['start_of_month'] },
    { value: 'month_end', label: dictionary['end_of_month'] }
  ]

  const INVOICING_FREQUENCY = [
    { value: 'monthly', label: dictionary['monthly'] },
    { value: 'quarterly', label: dictionary['quarterly'] },
    { value: 'annualy', label: dictionary['annually'] }
  ]

  const BILLING_OPTION_ITEMS = [
    { value: '30_days', label: dictionary['30_days'] },
    { value: '45_days', label: dictionary['45_days'] },
    { value: '60_days', label: dictionary['60_days'] },
    { value: '75_days', label: dictionary['75_days'] },
    { value: '90_days', label: dictionary['90_days'] },
    { value: 'end_of_month', label: dictionary['end_of_month'] },
    { value: 'on_reception', label: dictionary['on_reception'] },
    { value: 'due_on', label: dictionary['due_on'] },
    { value: 'free', label: dictionary['free'] },
    { value: '5_days', label: dictionary['5_days'] },
    { value: '10_days', label: dictionary['10_days'] }
  ]

  const INVOICE_DAYS = [
    {
      value: -5,
      label:
        lang === 'fr'
          ? `Du 5e au ${dictionary['last_day_of_month']}`
          : `5th to ${dictionary['last_day_of_month']}`
    },
    {
      value: -4,
      label:
        lang === 'fr'
          ? `Du 4e au ${dictionary['last_day_of_month']}`
          : `4th to ${dictionary['last_day_of_month']}`
    },
    {
      value: -3,
      label:
        lang === 'fr'
          ? `Du 3e au ${dictionary['last_day_of_month']}`
          : `3rd to  ${dictionary['last_day_of_month']}`
    },
    {
      value: -2,
      label:
        lang === 'fr'
          ? `Du 2e au ${dictionary['last_day_of_month']}`
          : `2nd ${dictionary['last_day_of_month']}`
    },
    { value: -1, label: dictionary['last_day_of_month'] }
  ]

  const DOCUMENT_TYPES = [
    { id: 0, value: 'receipt', label: dictionary.receipt },
    { id: 1, value: 'invoice', label: dictionary.invoice }
  ]

  return (
    <>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="dark.main">
            {dictionary.invoicing}
          </Typography>
          <Typography fontSize={13}>
            {dictionary.lease_invoicing_rules_desc}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="dark.main">
              {dictionary.invoicing_rules}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">
                {dictionary.invoicing_method}
              </Typography>
              <Controller
                control={control}
                name="invoicing_method"
                defaultValue="month_start"
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    variant="filled"
                    control={control}
                    InputProps={{ style: { height: '44px' } }}
                    sx={{
                      '& .MuiInputBase-input': {
                        paddingY: 0,
                        marginY: 0,
                        height: '100%'
                      }
                    }}
                  >
                    {INVOICING_METHODS.map(({ value, label }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        data-testid="MenuItem-a665d73a-7f28-4dcd-a022-4882378f3b1a"
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">
                {dictionary.invoicing_frequency}
              </Typography>
              <Controller
                control={control}
                name="invoicing_frequency"
                defaultValue="quarterly"
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    variant="filled"
                    // label={dictionary.invoicing_frequency}
                    control={control}
                    InputProps={{ style: { height: '44px' } }}
                    sx={{
                      '& .MuiInputBase-input': {
                        paddingY: 0,
                        marginY: 0,
                        height: '100%'
                      }
                    }}
                  >
                    {INVOICING_FREQUENCY.map(({ value, label }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        data-testid="MenuItem-7d624d4b-d15e-42c1-9031-ac717f22a683"
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">
                {dictionary.invoice_day}
              </Typography>
              <Controller
                control={control}
                name="invoice_day"
                defaultValue="1"
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    variant="filled"
                    control={control}
                    InputProps={{ style: { height: '44px' } }}
                    sx={{
                      '& .MuiInputBase-input': {
                        paddingY: 0,
                        marginY: 0,
                        height: '100%'
                      }
                    }}
                  >
                    {[...Array(25).keys()].map(i => (
                      <MenuItem key={i} value={i + 1}>
                        {getDayString(i + 1)}
                      </MenuItem>
                    ))}
                    {INVOICE_DAYS.map(({ value, label }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        data-testid="MenuItem-e67359e2-685f-4e40-ad6c-b8a5054bcf65"
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">
                {dictionary.billing_option}
              </Typography>
              <Controller
                control={control}
                name="billing_option"
                defaultValue="30_days"
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    variant="filled"
                    // label={dictionary.billing_option}
                    control={control}
                    InputProps={{ style: { height: '44px' } }}
                    sx={{
                      '& .MuiInputBase-input': {
                        paddingY: 0,
                        marginY: 0,
                        height: '100%'
                      }
                    }}
                  >
                    {BILLING_OPTION_ITEMS.map(({ value, label }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        data-testid="MenuItem-bf9ae832-fe12-4f7c-a64f-4cee3bcad1d0"
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">
                {dictionary.document_type}
              </Typography>
              <Controller
                control={control}
                name="document_type"
                defaultValue={isCompany ? 'invoice' : 'receipt'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    variant="filled"
                    // label={dictionary.document_type}
                    control={control}
                    InputProps={{ style: { height: '44px' } }}
                    sx={{
                      '& .MuiInputBase-input': {
                        paddingY: 0,
                        marginY: 0,
                        height: '100%'
                      }
                    }}
                  >
                    {DOCUMENT_TYPES.map(({ value, label }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        data-testid="MenuItem-4e0eae25-f58c-4577-9eb2-60113cff3543"
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="dark.main">
                {dictionary.emails}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack gap={1}>
                <Typography variant="caption">
                  {dictionary.Email_for_invoices}
                </Typography>
                <Controller
                  control={control}
                  name="invoice_email_to"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      type="email"
                      label={dictionary.to}
                      error={Boolean(errors.invoice_email_to)}
                      errorMessage={
                        errors.invoice_email_to &&
                        errors.invoice_email_to.message
                      }
                      InputProps={{
                        style: { height: '44px', paddingBottom: '10px' }
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack gap={1}>
                <Typography variant="caption">
                  {dictionary.invoice_email_cc}
                </Typography>
                <Controller
                  control={control}
                  name="invoice_email_cc"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      type="email"
                      label={dictionary.cc}
                      error={Boolean(errors.invoice_email_cc)}
                      errorMessage={
                        errors.invoice_email_cc &&
                        errors.invoice_email_cc.message
                      }
                      InputProps={{
                        style: { height: '44px', paddingBottom: '10px' }
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack gap={1}>
                <Typography variant="caption">{t('bcc')}</Typography>
                <Controller
                  control={control}
                  name="invoice_email_bcc"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      type="email"
                      label={dictionary.bcc}
                      error={Boolean(errors.invoice_email_bcc)}
                      errorMessage={
                        errors.invoice_email_bcc &&
                        errors.invoice_email_bcc.message
                      }
                      InputProps={{
                        style: { height: '44px', paddingBottom: '10px' }
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
