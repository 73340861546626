import React, { useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useTranslation } from '@contexts/translation'
import { useSnackbar } from 'notistack'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'

import { Grid, MenuItem, TextField } from '@mui/material'
import DatePicker from '@components/form/DatePicker'
import { format } from 'date-fns'
import { onCreateTicket, onUpdateTicket } from '@api/tickets'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import unformatDate from '@utils/unformatDate'
import { LoadingButton } from '@mui/lab'

export default function TicketForm(props) {
  const {
    operationId,
    ticketsURL,
    ticketId,
    leaseId,
    lotId,
    tenantId = null,
    handleClose
  } = props

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const operationFieldNeeded = !operationId || tenantId

  const { data: ticketData } = useFetchData(
    ticketId ? API_URL.TICKETS.GET_TICKET(ticketId) : null
  )

  const { data: operationsData } = useFetchData(
    !tenantId ? API_URL.PROJECTS.GET_PROJECT_LIST_PK : null
  )

  const tenantURL = tenantId ? API_URL.TENANTS.GET_TENANT_BY_ID(tenantId) : null
  const { data: tenantData } = useFetchData(tenantURL)

  const operations = useMemo(
    () => (tenantId ? tenantData?.operations : operationsData?.results),
    [tenantId, tenantData, operationsData]
  )

  const schema = yup.object().shape({
    title: yup.string().required(t('validation_required_field')),
    content: yup.string().required(t('validation_required_field')),
    priority: yup.string(),
    operation: !operationId
      ? yup.string().required(t('validation_required_field'))
      : yup.string(),
    status: yup.string(),
    due_date: yup.date().typeError(t('validation_required_field'))
  })

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      content: '',
      priority: '',
      operation: '',
      status: '',
      due_date: null
    }
  })

  useEffect(() => {
    if (ticketData) {
      setValue('title', ticketData.title)
      setValue('content', ticketData.content)
      setValue('priority', ticketData.priority)
      setValue('operation', ticketData.operation.id)
      setValue('status', ticketData.status)
      setValue(
        'due_date',
        ticketData?.due_date ? unformatDate(ticketData?.due_date) : null
      )
    }
  }, [ticketData, setValue])

  const onCreate = async values => {
    setLoading(true)

    const payload = values
    if (!operationFieldNeeded) {
      payload.operation = Number(operationId)
    }
    if (values.due_date) {
      payload.due_date = format(new Date(values.due_date), 'dd-MM-yyyy')
    }

    if (leaseId) {
      payload.lease = Number(leaseId)
    }
    if (lotId) {
      payload.lot = Number(lotId)
    }

    let response
    if (ticketId) {
      response = await onUpdateTicket(ticketId, payload)
    } else {
      response = await onCreateTicket(payload)
    }

    responseHandler({
      response,
      callback: async () => {
        await mutate(ticketsURL)
      },
      t,
      msgSuccess: ticketId
        ? t('response_message_ticket_updated')
        : t('response_message_ticket_created'),
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    handleClose?.()
  }

  const priorities = useMemo(() => {
    return [
      { label: t('priority_urgent'), value: '0urgent' },
      { label: t('priority_high'), value: '1high' },
      { label: t('priority_medium'), value: '2medium' },
      { label: t('priority_low'), value: '3low' }
    ]
  }, [t])

  const statuses = useMemo(() => {
    return [
      { label: t('ticketing_status_opened'), value: 'opened' },
      { label: t('ticketing_status_reopened'), value: 'reopened' },
      { label: t('ticketing_status_resolved'), value: 'resolved' },
      { label: t('ticketing_status_closed'), value: 'closed' },
      { label: t('ticketing_status_duplicate'), value: 'duplicate' }
    ]
  }, [t])

  return (
    <Grid item xs={12}>
      <form
        data-cy={'form'}
        noValidate={true}
        onSubmit={handleSubmit(onCreate)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="title"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  variant="filled"
                  label={t('title')}
                  error={Boolean(errors.title)}
                  helperText={errors.title && errors.title.message}
                  InputProps={{
                    style: {
                      height: '44px',
                      paddingBottom: '5px'
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="content"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  multiline
                  minRows={3}
                  variant="filled"
                  label={t('content')}
                  error={Boolean(errors.content)}
                  helperText={errors.content && errors.content.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="priority"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  variant="filled"
                  label={t('priority')}
                  error={Boolean(errors.priority)}
                  helperText={errors.priority && errors.priority.message}
                  InputProps={{
                    style: {
                      height: '44px',
                      paddingBottom: '5px'
                    }
                  }}
                >
                  {priorities.map((priority, idx) => (
                    <MenuItem
                      key={idx}
                      value={priority.value}
                      data-testid="MenuItem-70539ef1-8b3c-4729-b175-be3985e5aff2"
                    >
                      {priority.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          {operationFieldNeeded && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name="operation"
                defaultValue={
                  operationId
                    ? operationId
                    : operations?.length === 1
                    ? operations[0]?.id
                    : ''
                }
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    required
                    label={t('operation')}
                    error={Boolean(errors.operation)}
                    helperText={errors.operation?.message}
                    InputProps={{
                      style: {
                        height: '44px',
                        paddingBottom: '5px'
                      }
                    }}
                  >
                    {operations?.map(operation => (
                      <MenuItem
                        key={operation.id}
                        value={operation.id}
                        data-testid="MenuItem-a7305f88-ec27-4c7b-a43a-303dcbb855ed"
                      >
                        {operation.display}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              control={control}
              name="status"
              defaultValue="opened"
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  variant="filled"
                  label={t('status')}
                  error={Boolean(errors.status)}
                  helperText={errors.status && errors.status.message}
                  InputProps={{
                    style: {
                      height: '44px',
                      paddingBottom: '5px'
                    }
                  }}
                >
                  {statuses.map((status, idx) => (
                    <MenuItem
                      key={idx}
                      value={status.value}
                      data-testid="MenuItem-ad994b3b-7783-470d-aaa4-df6f76dbaf69"
                    >
                      {status.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              required
              name="due_date"
              label={t('due_date')}
              variant="outlined"
              fullWidth
              control={control}
              error={Boolean(errors.due_date)}
              errorMessage={errors.due_date?.message}
              data_testid="DatePicker-8d2fd5bb-8e1f-4f56-bc4a-008d11245660"
              InputProps={{
                style: {
                  height: '44px'
                }
              }}
            />
          </Grid>

          <Grid item xs={12} align="right">
            <LoadingButton
              type="submit"
              loading={loading}
              data-testid="Button-998e1d2f-67ba-4bea-9163-63aa63608ed6"
            >
              {t('save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}
