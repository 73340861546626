import React, { useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'

import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import API_URL from '@config/services'
import { useFetchData } from '@api/fetchData'
import PlaceHolder from '@components/common/PlaceHolder'

export default function DatesTable(props) {
  const { leaseId } = props
  const { dictionary } = useTranslation()
  const classesTable = useTableStyles()

  const [periods, setPeriods] = useState([])

  const fetchURL = leaseId ? API_URL.LEASES.GET_LEASE_PERIODS(leaseId) : null

  const { data, isLoading } = useFetchData(fetchURL)

  useEffect(() => {
    if (data) setPeriods(data.results)
  }, [data])

  return (
    <Grid item xs={12}>
      <TableContainer className={classesTable.container}>
        <Table>
          <TableHead>
            <TableRow hover={false}>
              <TableCell className={classesTable.headerCell}>
                {dictionary.period}
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                {dictionary.start_date}
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                {dictionary.end_date}
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                {dictionary.duration}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow className={classesTable.row}>
                <TableCell align="center" colSpan={'100%'}>
                  <CircularProgress color="secondary" />
                </TableCell>
              </TableRow>
            )}
            {!isLoading && data && !data.results.length && (
              <TableRow hover={false} className={classesTable.row}>
                <TableCell align="center" colSpan={'100%'}>
                  <PlaceHolder
                    src="/Placeholders/Keysy/Baux.png"
                    width={'500px'}
                    height={'450px'}
                  />
                </TableCell>
              </TableRow>
            )}
            {periods.map(period => (
              <TableRow
                key={period.id}
                hover={false}
                className={classesTable.row}
              >
                <TableCell>{period.order}</TableCell>
                <TableCell>{period.start_date}</TableCell>
                <TableCell>{period.end_date}</TableCell>
                <TableCell>
                  {period.num_months} {dictionary.months}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}
