import React from 'react'

import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'
import { useTheme } from '@mui/material/styles'

import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import PlaceHolder from '@components/common/PlaceHolder'
import numberWithCommas from '@utils/numberWithCommas'

export default function LeaseFormLotsTable(props) {
  const { articles, isLoading, currency, setFilterId } = props
  const { dictionary, t } = useTranslation()
  const classesTable = useTableStyles()
  const theme = useTheme()

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer
            sx={{
              maxHeight: '250px',
              overflowY: 'scroll',
              border: `1px solid ${theme.palette.other.stroke}`,
              borderRadius: theme.spacing(3),
              overflow: 'auto'
            }}
          >
            <Table>
              <TableHead>
                <TableRow hover={false} sx={{ height: '44px' }}>
                  <TableCell></TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.lot}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary.surface}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary['rent/m2']}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {t('rent_excl_taxes')}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary['expenses/m2']}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary.expenses}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <CircularProgress color="secondary" />
                    </TableCell>
                  </TableRow>
                )}

                {!isLoading && !articles?.length && (
                  <TableRow hover={false} className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <PlaceHolder
                        src="/Placeholders/Project_Settings_Clients.png"
                        width={'180px'}
                        height={'180px'}
                      />
                    </TableCell>
                  </TableRow>
                )}

                {!isLoading &&
                  articles?.length > 0 &&
                  articles?.map((article, idx) => (
                    <TableRow
                      key={idx}
                      onClick={() => setFilterId(article.id)}
                      sx={{
                        height: '60px',
                        backgroundColor: `${theme.palette.elements.white}`,
                        '&:hover': {
                          cursor: 'pointer'
                        },
                        '&:last-child td, &:last-child th': {
                          borderBottom: 0,
                          borderTop: 0
                        }
                      }}
                    >
                      <TableCell
                        sx={{
                          '&.MuiTableCell-root': {
                            borderBottom: 'none',
                            paddingLeft: 3
                          }
                        }}
                      >
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: theme.palette.highlight.lightPurple
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                      >
                        <Typography
                          fontSize={12}
                          fontWeight={700}
                          data-testid="Typography-c54af787-2b55-4c12-8179-d6b39615b51c"
                        >
                          {article.lot_display}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                      >
                        {numberWithCommas(article.contract_surface_area, 2)}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                      >
                        {`${numberWithCommas(
                          article.price_per_surface,
                          2
                        )} ${currency}`}
                      </TableCell>
                      <TableCell
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                        align="right"
                      >
                        {numberWithCommas(article.rent, 2, currency)}
                      </TableCell>
                      <TableCell
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                        align="right"
                      >
                        {`${numberWithCommas(
                          article.contract_budget_per_surface,
                          2
                        )} ${currency}`}
                      </TableCell>
                      <TableCell
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                        align="right"
                      >
                        {numberWithCommas(
                          article.budget_provision,
                          2,
                          currency
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                      />
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* <DeleteModal
        open={deleteModal.open}
        onClose={() => setDeleteModal({ open: false, id: null })}
        onConfirm={() => onConfirmDelete()}
        description={dictionary.confirm_action_delete_article}
        // loading={loading}
      /> */}
    </>
  )
}
