import React, { useCallback, useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'

import {
  CircularProgress,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import PlaceHolder from '@components/common/PlaceHolder'
import { format } from '@utils/date-fns_functions'
import numberWithCommas from '@utils/numberWithCommas'
import capitalizeFirstLetter from '@utils/capitalize'

export default function InvoicingScheduleTable(props) {
  const { leaseId, editable, onEditAmount, startDate, endDate, currency } =
    props
  const { dictionary, t, lang } = useTranslation()
  const classesTable = useTableStyles()

  const [schedules, setSchedules] = useState({})
  const [selectedSchedule, setSelectedSchedule] = useState([])
  const [years, setYears] = useState([])
  const [year, setYear] = useState('')

  const currentYear = new Date().getFullYear()

  const fetchURL = leaseId ? API_URL.LEASES.GET_LEASE_SCHEDULE(leaseId) : null
  const { data, isLoading } = useFetchData(fetchURL)

  const getYears = data => {
    const years = data.years?.map(
      year => year.start_date.split('-')[year.start_date.split('-').length - 1]
    )

    setYears(years)
    setYear(currentYear.toString())
  }

  const getMonthAndYear = (date, lang) => {
    const [day, month, year] = date.split('-')
    return capitalizeFirstLetter(
      format(new Date(year, month - 1, day), 'MMMM yyyy', lang)
    )
  }

  const withinRange = useCallback(
    monthYear => {
      const start = new Date(startDate)
      const e = new Date(endDate)
      const end = new Date(e.getFullYear(), e.getMonth() + 1, 0)
      const current = new Date(monthYear)

      const currentMonth = current.getMonth()
      const currentYear = current.getFullYear()
      const startMonth = start.getMonth()
      const startYear = start.getFullYear()
      const endMonth = end.getMonth()
      const endYear = end.getFullYear()

      const startDate2 = new Date(startYear, startMonth)
      const endDate2 = new Date(endYear, endMonth)
      const currentDate2 = new Date(currentYear, currentMonth)

      if (
        (currentDate2.getTime() > startDate2.getTime() ||
          currentDate2.getTime() === startDate2.getTime()) &&
        (currentDate2.getTime() < endDate2.getTime() ||
          currentDate2.getTime() === endDate2.getTime())
      ) {
        return true
      }

      return false
    },
    [endDate, startDate]
  )

  const getSchedules = useCallback(
    data => {
      const schedules = []

      for (let i = 0; i < data.years.length; i++) {
        const schedule = {}

        const year =
          data.years[i].start_date.split('-')[
            data.years[i].start_date.split('-').length - 1
          ]

        schedule.year = year

        let scheduleData = []
        data.years[i].quarters.map((quarter, idx) => {
          const quarterData = []
          const withinQuarterRange = quarter.months.filter(month =>
            withinRange(getMonthAndYear(month.start_date, lang))
          )

          quarter.months.map(month => {
            quarterData.push({
              start_date: getMonthAndYear(month.start_date, lang),
              rent: month.rent,
              adjusted_rent: month.adjusted_rent,
              discount: month.discount,
              charges: month.charges,
              total: month.total,
              adjusted_total: month.adjusted_total,
              inRange: withinRange(getMonthAndYear(month.start_date, lang))
            })
          })
          quarterData.push({
            start_date: dictionary[`q${idx + 1}`],
            rent: quarter.months
              .map(month => month.rent)
              .reduce((a, b) => a + b, 0),
            adjusted_rent: quarter.months
              .map(month => month.adjusted_rent)
              .reduce((a, b) => a + b, 0),
            discount: quarter.months
              .map(month => month.discount)
              .reduce((a, b) => a + b, 0),
            charges: quarter.months
              .map(month => month.charges)
              .reduce((a, b) => a + b, 0),
            total: quarter.months
              .map(month => month.total)
              .reduce((a, b) => a + b, 0),
            adjusted_total: quarter.months
              .map(month => month.adjusted_total)
              .reduce((a, b) => a + b, 0),
            inRange: withinQuarterRange?.length > 0
          })
          scheduleData = [...scheduleData, ...quarterData]
        })
        schedule.data = scheduleData

        schedules.push(schedule)
      }

      setSchedules(schedules)
    },
    [dictionary, withinRange, lang]
  )

  const footerData = data => ({
    rent: data
      .map(item => (item.start_date[0] === 'T' ? item.rent : 0))
      .reduce((a, b) => a + b, 0),
    adjusted_rent: data
      .map(item => (item.start_date[0] === 'T' ? item.adjusted_rent : 0))
      .reduce((a, b) => a + b, 0),
    discount: data
      .map(item => (item.start_date[0] === 'T' ? item.discount : 0))
      .reduce((a, b) => a + b, 0),
    charges: data
      .map(item => (item.start_date[0] === 'T' ? item.charges : 0))
      .reduce((a, b) => a + b, 0),
    total: data
      .map(item => (item.start_date[0] === 'T' ? item.total : 0))
      .reduce((a, b) => a + b, 0),
    adjusted_total: data
      .map(item => (item.start_date[0] === 'T' ? item.adjusted_total : 0))
      .reduce((a, b) => a + b, 0)
  })

  useEffect(() => {
    if (data) {
      getSchedules(data)
      getYears(data)
    }
  }, [data, getSchedules])

  useEffect(() => {
    if (year && year.length > 0) {
      const selected = schedules.find(schedule => schedule.year === year)
      if (selected) setSelectedSchedule(selected.data)
    }
  }, [year, schedules])

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            variant="h6"
            data-testid="Typography-d5ff6a89-5c2f-4bec-a90a-bb24b07061c1"
          >
            {t('invoicing_schedule')}
          </Typography>
          <Grid item xs={2} md={1}>
            <TextField
              name="year"
              select
              fullWidth
              variant="filled"
              label={dictionary.year}
              value={year}
              onChange={e => setYear(e.target.value)}
              sx={{
                '& .MuiFilledInput-input': {
                  paddingTop: 2,
                  paddingBottom: 1.1
                }
              }}
            >
              {years.map((year, idx) => (
                <MenuItem
                  key={idx}
                  value={year}
                  data-testid="MenuItem-6beb21c8-77ea-4809-8739-06897c7dbd3f"
                >
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer className={classesTable.container}>
            <Table>
              <TableHead>
                <TableRow hover={false}>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.start_date}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary.rent}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary.adjusted_rent}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary.discount}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary.charges}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary.total}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <CircularProgress color="secondary" />
                    </TableCell>
                  </TableRow>
                )}

                {!isLoading && data && !data.years?.length && (
                  <TableRow hover={false} className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <PlaceHolder
                        src="/Placeholders/Keysy/Invoice.png"
                        width={'500px'}
                        height={'450px'}
                      />
                    </TableCell>
                  </TableRow>
                )}

                {selectedSchedule.map((schedule, idx) => {
                  const amountStyle =
                    (idx + 1) % 4 === 0 ? { fontWeight: 'bold' } : {}
                  return (
                    <TableRow
                      key={idx}
                      style={
                        (idx + 1) % 4 === 0
                          ? { backgroundColor: 'whitesmoke' }
                          : {}
                      }
                    >
                      <TableCell style={amountStyle}>
                        {schedule.start_date}
                      </TableCell>
                      <TableCell
                        style={
                          editable
                            ? {
                                ...amountStyle,
                                color: '#1AB394',
                                cursor: 'pointer'
                              }
                            : amountStyle
                        }
                        onClick={
                          editable ? () => onEditAmount?.(schedule, year) : null
                        }
                        align="right"
                      >
                        {numberWithCommas(schedule.rent, 2, currency)}
                      </TableCell>
                      <TableCell style={amountStyle} align="right">
                        <Tooltip
                          title={schedule.adjusted_rent / schedule.rent || 0}
                        >
                          <span data-testid="span-728686b4-3b06-46d8-a657-b5709b7c85e6">
                            {numberWithCommas(
                              schedule.adjusted_rent,
                              2,
                              currency
                            )}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={amountStyle}
                        align="right"
                        onClick={() => onEditAmount?.(schedule, year)}
                      >
                        {numberWithCommas(schedule.discount, 2, currency)}
                      </TableCell>
                      <TableCell style={amountStyle} align="right">
                        {numberWithCommas(schedule.charges, 2, currency)}
                      </TableCell>
                      <TableCell style={amountStyle} align="right">
                        {numberWithCommas(schedule.adjusted_total, 2, currency)}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              {!isLoading && schedules.length > 0 && (
                <TableFooter className={classesTable.footer}>
                  <TableRow hover={false} className={classesTable.rowTotal}>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold' }}
                        color="secondary"
                        data-testid="Typography-3108ad4e-2f01-404c-b4ba-12628a7c4b6c"
                      >
                        {year}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        style={
                          editable
                            ? {
                                fontWeight: 'bold',
                                color: '#1AB394',
                                cursor: 'pointer'
                              }
                            : {
                                fontWeight: 'bold'
                              }
                        }
                        onClick={() => onEditAmount?.(selectedSchedule, year)}
                        color="secondary"
                        data-testid="Typography-81b4c0f0-ce6d-46e1-91a6-3ee8d7ac817e"
                      >
                        {numberWithCommas(
                          footerData(selectedSchedule).rent,
                          2,
                          currency
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold' }}
                        color="secondary"
                        data-testid="Typography-38e68241-c2a7-4a33-ab49-2f10fa6f0b5d"
                      >
                        {numberWithCommas(
                          footerData(selectedSchedule).adjusted_rent,
                          2,
                          currency
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold' }}
                        color="secondary"
                        onClick={() => onEditAmount?.(selectedSchedule, year)}
                        data-testid="Typography-bebffc87-ae0b-42a6-ab38-b16e07c9e20a"
                      >
                        {numberWithCommas(
                          footerData(selectedSchedule).discount,
                          2,
                          currency
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold' }}
                        color="secondary"
                        data-testid="Typography-eb3fbe5b-2393-4982-8094-1333b24f37a6"
                      >
                        {numberWithCommas(
                          footerData(selectedSchedule).charges,
                          2,
                          currency
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold' }}
                        color="secondary"
                        data-testid="Typography-7867ce7f-90e0-4a45-8b38-f97947f74f73"
                      >
                        {numberWithCommas(
                          footerData(selectedSchedule).adjusted_total,
                          2,
                          currency
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}
