import React, { useEffect, useState } from 'react'

import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import { useTranslation } from '@contexts/translation'

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import numberWithCommas from '@utils/numberWithCommas'
import { format } from 'date-fns'
import { responseHandler } from '@utils/responseHandler'
import { onDeleteLease, onUpdateLease } from '@api/lease'
import { useSnackbar } from 'notistack'
import { mutate } from 'swr'
import LeaseForm from '@components/Leases/LeaseForm'
import DeleteModal from '@components/common/DeleteModal'
import {
  ArrowForward,
  Description,
  DirectionsCarOutlined,
  Draw,
  InsertDriveFile,
  LibraryBooksOutlined,
  PlayArrow,
  ViewQuiltOutlined
} from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import queryString from '@utils/queryString'
import FilterActionsSelector from '@components/common/Filter/ActionsSelector'

export default function Info(props) {
  const {
    operationId,
    leaseId,
    currency,
    fetchURL,
    handleClose,
    cancelStatus,
    setValue,
    ACTIONS,
    leaseData
  } = props
  const { dictionary, t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()

  const [loading, setLoading] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [editLeaseModal, setEditLeaseModal] = useState({
    open: false,
    id: null
  })
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null
  })
  const [lotsCount, setLotsCount] = useState(0)
  const [lotsSurface, setLotsSurface] = useState(0)

  const leaseURL = leaseId ? API_URL.LEASES.GET_LEASE(leaseId) : null
  const { data, isLoading } = useFetchData(leaseURL)

  const articlesURL = leaseId
    ? API_URL.LEASES.GET_LEASE_ARTICLES(leaseId)
    : null
  const { data: articlesData } = useFetchData(articlesURL)

  useEffect(() => {
    if (articlesData) {
      setLotsCount(articlesData.count)
      setLotsSurface(
        articlesData?.results
          ?.map(item => item?.contract_surface_area)
          .reduce((a, b) => a + b, 0) - data?.surface_rented_parking
      )
    }
  }, [articlesData, data])

  const invoicesURL =
    API_URL.INVOICES.GET_INVOICES_LIST_PK() +
    '?' +
    queryString({ lease: leaseId })
  const { data: invoicesData } = useFetchData(invoicesURL)

  const totalInvoicesBalance = () => {
    let total = 0
    const balances = invoicesData?.results.map(item => item.balance)
    if (balances && balances.length > 0) {
      total = balances.reduce((a, b) => a + b)
    }
    return total
  }

  useEffect(() => {
    setInvoices(invoicesData?.results)
  }, [invoicesData])

  const formatDate = date => {
    if (date) {
      const [day, month, year] = date.split('-')
      return format(new Date(year, month - 1, day), 'dd/MM/yyyy', {
        // locale: lang === 'fr' ? locales['fr'] : locales['enUS']
      })
    }
  }

  const [description, setDescription] = useState('')
  const [changeDesc, setChangeDesc] = useState(false)

  const handleDescriptionClick = async () => {
    setLoading(true)
    const payload = { description: description }

    const response = await onUpdateLease(leaseId, payload)
    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchURL)
        await mutate(leaseURL)
      },
      dictionary,
      msgSuccess: dictionary.lease_updated,
      snackbar: enqueueSnackbar
    })
    handleClose?.()
    setLoading(false)
  }

  useEffect(() => {
    if (data) {
      setDescription(data?.description)
    }
  }, [data])

  if (isLoading) {
    return (
      <Grid item xs={12} align="center">
        <CircularProgress color="secondary" sx={{ m: 10 }} />
      </Grid>
    )
  }

  const Title = ({ title }) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: 20,
          fontWeight: 500,
          color: theme.palette.dark.main
        }}
      >
        <div>{title}</div>
      </div>
    )
  }

  const onConfirmDelete = async () => {
    setLoading(true)
    const response = await onDeleteLease(deleteModal.id)

    responseHandler({
      response,
      callback: async () => await mutate(fetchURL),
      dictionary,
      msgSuccess: dictionary.lease_deleted,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    setDeleteModal({ open: false, id: null })
    handleClose?.()
  }

  const convertInvoiceDay = invoiceDay => {
    switch (parseInt(invoiceDay)) {
      case 1:
        return `${invoiceDay}${dictionary.st}`
      case 2:
        return `${invoiceDay}${dictionary.nd}`
      case 3:
        return `${invoiceDay}${dictionary.th}`
      case 4:
        return `${invoiceDay}${dictionary.th}`
      case 5:
        return `${invoiceDay}${dictionary.th}`
      case 6:
        return `${invoiceDay}${dictionary.th}`
      case -5:
        return `${dictionary.from_date} ${Math.abs(parseInt(invoiceDay))}${
          dictionary.th
        } ${dictionary.to_date} ${dictionary.last_day_of_month}`
      case -4:
        return `${dictionary.from_date} ${Math.abs(parseInt(invoiceDay))}${
          dictionary.th
        } ${dictionary.to_date} ${dictionary.last_day_of_month}`
      case -3:
        return `${dictionary.from_date} ${Math.abs(parseInt(invoiceDay))}${
          dictionary.rd
        } ${dictionary.to_date} ${dictionary.last_day_of_month}`
      case -2:
        return `${dictionary.from_date} ${Math.abs(parseInt(invoiceDay))}${
          dictionary.nd
        } ${dictionary.to_date} ${dictionary.last_day_of_month}`
      case -1:
        return dictionary.last_day_of_month
      default:
        return ''
    }
  }

  const invoiceChipStatusColors = status => {
    switch (status) {
      case 'not_valid':
        return {
          label: dictionary.not_valid,
          color: 'highlight.red',
          backgroundColor: 'highlight.lightRed'
        }
      case 'late':
        return {
          label: dictionary.late,
          color: 'highlight.red',
          backgroundColor: 'highlight.lightRed'
        }
      case 'partial':
        return {
          label: dictionary.partial,
          color: 'highlight.green',
          backgroundColor: 'highlight.lightGreen'
        }
      case 'not_paid':
        return {
          label: dictionary.not_paid,
          color: 'highlight.gold',
          backgroundColor: 'highlight.lightGold'
        }
      case 'paid':
        return {
          label: dictionary.paid,
          color: 'highlight.green',
          backgroundColor: 'highlight.lightGreen'
        }
      case 'closed':
        return {
          label: dictionary.closed,
          color: 'highlight.violet',
          backgroundColor: 'highlight.lightPurple'
        }
      case 'not_sent':
        return {
          label: dictionary.not_sent,
          color: 'highlight.gold',
          backgroundColor: 'highlight.lightGold'
        }
      case 'litigious':
        return {
          label: dictionary.litigious,
          color: 'highlight.red',
          backgroundColor: 'highlight.lightRed'
        }
      case 'canceled':
        return {
          label: dictionary.canceled,
          color: 'highlight.red',
          backgroundColor: 'highlight.lightRed'
        }
      case 'autodraft':
        return {
          label: dictionary.autodraft,
          color: 'highlight.green',
          backgroundColor: 'highlight.lightGreen'
        }
      default:
        return ''
    }
  }

  const translateBillingOption = option => {
    switch (option) {
      case '30_days':
        return dictionary['30_days']
      case '45_days':
        return dictionary['45_days']
      case '60_days':
        return dictionary['60_days']
      case '75_days':
        return dictionary['75_days']
      case '90_days':
        return dictionary['90_days']
      case 'end_of_month':
        return dictionary['end_of_month']
      case 'on_reception':
        return dictionary['on_reception']
      case 'due_on':
        return dictionary['due_on']
      case 'free':
        return dictionary['free']
      case '5_days':
        return dictionary['5_days']
      case '10_days':
        return dictionary['10_days']
      default:
        return ''
    }
  }

  const leaseChipStatusColors = status => {
    switch (status) {
      case 'draft':
        return {
          label: dictionary.draft,
          color: 'highlight.red',
          backgroundColor: 'highlight.lightRed'
        }
      case 'prospect':
        return {
          label: dictionary.prospect,
          color: 'highlight.blue',
          backgroundColor: 'highlight.lightBlue'
        }
      case 'not_started':
        return {
          label: dictionary.not_started,
          color: 'primary.main',
          backgroundColor: 'highlight.lightPurple'
        }
      case 'active':
        return {
          label: dictionary.active_m,
          color: 'highlight.green',
          backgroundColor: 'highlight.lightGreen'
        }
      case 'expired':
        return {
          label: dictionary.expired,
          color: 'dark.main',
          backgroundColor: 'highlight.lightBlack'
        }
      case 'canceled':
        return {
          label: dictionary.canceled,
          color: 'highlight.red',
          backgroundColor: 'highlight.lightRed'
        }
      case 'archived':
        return {
          label: dictionary.archived,
          color: 'highlight.gold',
          backgroundColor: 'highlight.lightGold'
        }
      default:
        return ''
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'space-between'
              }}
            >
              <Stack direction="row" spacing={2} alignItems="end">
                <Stack>
                  <Typography variant="h6">{dictionary.about_lease}</Typography>
                  <Typography variant="body2" color="text.light">
                    {dictionary.lease_details_info_desc}
                  </Typography>
                </Stack>
                <Chip
                  label={leaseChipStatusColors(data?.status).label}
                  sx={{
                    height: 27,
                    fontSize: 12,
                    fontWeight: 500,
                    color: leaseChipStatusColors(data?.status).color,
                    backgroundColor: leaseChipStatusColors(data?.status)
                      .backgroundColor,
                    borderRadius: '8px'
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <Button
                  variant="contained"
                  onClick={() => setEditLeaseModal({ open: true, id: leaseId })}
                >
                  {dictionary.edit}
                </Button>
                {ACTIONS ? (
                  <FilterActionsSelector
                    options={ACTIONS(leaseId, leaseData)}
                  />
                ) : (
                  <></>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ borderRadius: '20px' }}>
                <CardHeader title={<Title title={dictionary.information} />} />
                <CardContent
                  sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
                >
                  <Grid item xs={12} sx={{ display: 'flex', gap: 2 }}>
                    <Avatar sx={{ width: '44px', height: '44px' }} />
                    <Stack>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="text.secondary"
                        data-testid="Typography-eceb1450-602e-4d82-9db2-cc8d19e0e8e6"
                      >
                        {dictionary.tenant} :{' '}
                        <span style={{ fontWeight: 700 }}>
                          {data?.tenant?.display}
                        </span>
                      </Typography>
                      <Stack direction={'row'} spacing={1} alignItems="center">
                        <ViewQuiltOutlined sx={{ fontSize: 24 }} />
                        <Typography
                          sx={{ display: 'inline', textTransform: 'none' }}
                          fontWeight={500}
                          color={theme.palette.dark.main}
                          component="span"
                          variant="caption"
                          data-testid="Typography-8476fe47-f9b7-446f-8f1c-295c34853070"
                        >
                          {lotsCount} {dictionary.lots}
                        </Typography>
                        <div
                          style={{
                            width: '5px',
                            height: '5px',
                            borderRadius: '50%',
                            backgroundColor: theme.palette.text.light
                          }}
                        />
                        <LibraryBooksOutlined sx={{ fontSize: 18 }} />
                        <Typography
                          sx={{ display: 'inline', textTransform: 'none' }}
                          component="span"
                          variant="caption"
                          color={theme.palette.dark.main}
                          data-testid="Typography-e7efa7ed-596f-48d7-b19b-187063b25e49"
                        >
                          {lotsSurface}m2
                        </Typography>
                        <div
                          style={{
                            width: '5px',
                            height: '5px',
                            borderRadius: '50%',
                            backgroundColor: theme.palette.text.light
                          }}
                        />
                        <DirectionsCarOutlined sx={{ fontSize: 18 }} />
                        <Typography
                          sx={{ display: 'inline', textTransform: 'none' }}
                          component="span"
                          variant="caption"
                          data-testid="Typography-fb91be17-1b2e-4664-890d-2c3c60681318"
                          color={theme.palette.dark.main}
                        >
                          {data?.surface_rented_parking} {dictionary.parkings}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="dark.main"
                    >
                      {dictionary.details}
                    </Typography>
                    <Stack gap={1}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Draw sx={{ color: 'primary.main', fontSize: 14 }} />
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{ display: 'flex' }}
                          data-testid="Typography-551ee718-b111-4722-9f4d-56a3cb946483"
                        >
                          {`${
                            cancelStatus
                              ? dictionary.lease_canceled
                              : dictionary.lease_signed
                          }`}{' '}
                          :{' '}
                          {cancelStatus
                            ? formatDate(data?.cancel_date)
                            : formatDate(data?.date_signed)}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <PlayArrow
                          sx={{ color: 'primary.main', fontSize: 14 }}
                        />
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          data-testid="Typography-ac5811d9-e60e-455c-be54-22b2bf59c61c"
                        >
                          {dictionary.start_date} :{' '}
                          {formatDate(data?.start_date)}
                          {/*  get operation data? for unit */}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Description
                          sx={{ color: 'primary.main', fontSize: 14 }}
                        />
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          data-testid="Typography-181be9a9-bb5f-48d7-87e8-6a66703aa8e8"
                        >
                          {dictionary.end_date} : {formatDate(data?.end_date)}
                          {/*  get operation data? for unit */}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    padding={2}
                    sx={{
                      border: '1px solid #E0E3E8',
                      borderRadius: '12px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2
                    }}
                  >
                    <Stack gap={0.5}>
                      <Typography
                        variant="caption"
                        fontSize={13}
                        color="text.light"
                        data-testid="Typography-2dd712b4-78da-4e79-8d7e-2be225d90356"
                      >
                        {dictionary.rent}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        color="dark.main"
                        data-testid="Typography-2dd712b4-78da-4e79-8d7e-2be225d90356"
                      >
                        {numberWithCommas(
                          Math.round(data?.rent * 100) / 100 || 0,
                          0,
                          currency
                        )}
                      </Typography>
                    </Stack>
                    <Stack gap={0.5}>
                      <Typography
                        variant="caption"
                        fontSize={13}
                        color="text.light"
                        data-testid="Typography-4a1ec728-fef4-4b60-a8b5-22e9649c0a89"
                      >
                        {dictionary.provision}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        color="dark.main"
                        data-testid="Typography-4a1ec728-fef4-4b60-a8b5-22e9649c0a89"
                      >
                        {numberWithCommas(
                          Math.round(data?.budget * 100) / 100,
                          0,
                          currency
                        )}
                      </Typography>
                    </Stack>
                    <Stack gap={0.5}>
                      <Typography
                        variant="caption"
                        fontSize={13}
                        color="text.light"
                        data-testid="Typography-6889792e-8844-4d90-b2e2-e7276911c478"
                      >
                        {dictionary.deposit}
                      </Typography>
                      <Stack>
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          color="dark.main"
                          data-testid="Typography-6889792e-8844-4d90-b2e2-e7276911c478"
                        >
                          {numberWithCommas(
                            Math.round(data?.deposit * 100) / 100 || 0,
                            0,
                            currency
                          )}
                        </Typography>
                        <Typography
                          variant="caption"
                          fontSize={13}
                          color="text.dark"
                          data-testid="Typography-6889792e-8844-4d90-b2e2-e7276911c478"
                        >
                          {data?.deposit_months > 0 &&
                            data?.deposit_months + ' ' + dictionary.months}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack gap={0.5}>
                      <Typography
                        variant="caption"
                        fontSize={13}
                        color="text.light"
                        data-testid="Typography-6889792e-8844-4d90-b2e2-e7276911c478"
                      >
                        {t('guarantee')}
                      </Typography>
                      <Stack>
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          color="dark.main"
                          data-testid="Typography-6889792e-8844-4d90-b2e2-e7276911c478"
                        >
                          {numberWithCommas(
                            Math.round(data?.guarantee * 100) / 100 || 0,
                            0,
                            currency
                          )}
                        </Typography>
                        <Typography
                          variant="caption"
                          fontSize={13}
                          color="text.dark"
                          data-testid="Typography-6889792e-8844-4d90-b2e2-e7276911c478"
                        >
                          {data?.guarantee_months > 0 &&
                            data?.guarantee_months + ' ' + dictionary.months}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ borderRadius: '20px' }}>
                <CardHeader title={<Title title={dictionary.contact} />} />
                <CardContent>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="dark.main"
                    >
                      {dictionary.email}
                    </Typography>
                    <Stack gap={1}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Description
                          sx={{ color: 'primary.main', fontSize: 14 }}
                        />
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{ display: 'flex' }}
                          data-testid="Typography-551ee718-b111-4722-9f4d-56a3cb946483"
                        >
                          {`${dictionary.to}: `}
                          {data?.invoice_email_to}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Description
                          sx={{ color: 'primary.main', fontSize: 14 }}
                        />
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{ display: 'flex' }}
                          data-testid="Typography-0abc8ce5-82c9-4ccb-900b-9ffec180bff0"
                        >
                          {`${dictionary.cc}: `}
                          {data?.invoice_email_cc}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ borderRadius: '20px' }}>
                <Grid
                  xs={12}
                  sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                >
                  <CardHeader title={<Title title={dictionary.invoicing} />} />
                  <Grid
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      pr: 3
                    }}
                  >
                    <Grid
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <Chip
                        label={
                          data?.invoicing_frequency === 'monthly'
                            ? dictionary.monthly
                            : data?.invoicing_frequency === 'quarterly'
                            ? dictionary.quarterly
                            : dictionary.annually
                        }
                        sx={{
                          height: 27,
                          fontSize: 12,
                          fontWeight: 500,
                          color: theme.palette.dark.main,
                          backgroundColor: theme.palette.highlight.lightBlack,
                          borderRadius: '8px'
                        }}
                      />
                      <Chip
                        label={translateBillingOption(data?.billing_option)}
                        sx={{
                          height: 27,
                          fontSize: 12,
                          fontWeight: 500,
                          color: theme.palette.highlight.blue,
                          backgroundColor: theme.palette.highlight.lightBlue,
                          borderRadius: '8px'
                        }}
                      />
                    </Grid>
                    <Typography
                      color="primary.main"
                      fontWeight={500}
                      fontSize={13}
                      sx={{
                        display: 'flex',
                        aligntItems: 'center',
                        gap: 1,
                        cursor: 'pointer'
                      }}
                      onClick={() => setValue('invoices')}
                    >
                      {dictionary.see}
                      <ArrowForward sx={{ fontSize: 18 }} />
                    </Typography>
                  </Grid>
                </Grid>
                <CardContent
                  sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
                >
                  <Grid item xs={12} sx={{ display: 'flex', gap: 1.5 }}>
                    <Grid
                      xs={7}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: 1.5
                      }}
                    >
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        data-testid="Typography-6a6c6db8-36c6-4233-85db-4669fac9e0f4"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          paddingLeft: '2px'
                        }}
                      >
                        <span
                          style={{
                            width: '11.25px',
                            height: '11.25px',
                            borderRadius: '2px',
                            color: 'white',
                            backgroundColor: theme.palette.primary.main,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 9
                          }}
                        >
                          {parseInt(data?.invoice_day)}
                        </span>
                        {dictionary.invoice_day} :{' '}
                        {convertInvoiceDay(data?.invoice_day)}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        data-testid="Typography-0f1837fc-c42a-42ef-b002-5db132a3f969"
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Description
                          sx={{ color: 'primary.main', fontSize: 14 }}
                        />
                        {dictionary.invoicing_method} :{' '}
                        {data?.invoicing_method === 'month_start'
                          ? dictionary.start_of_month
                          : dictionary.end_of_month}
                      </Typography>
                    </Grid>
                    <Grid
                      xs={5}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: 1.5
                      }}
                    >
                      <Typography variant="caption" color="text.extralight">
                        {dictionary.balance}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        color="dark.main"
                      >
                        {numberWithCommas(totalInvoicesBalance(), 2, currency)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}
                  >
                    {invoices &&
                      invoices?.map((invoice, idx) => (
                        <Grid
                          key={idx}
                          xs={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '80%'
                          }}
                        >
                          <Stack direction="row" gap={2}>
                            <InsertDriveFile
                              sx={{
                                width: '44px',
                                height: '44px',
                                padding: '10px',
                                borderRadius: '50%',
                                backgroundColor: 'elements.light'
                              }}
                            />
                            <Stack>
                              <Typography
                                variant="body2"
                                fontWeight={500}
                                color="dark.main"
                              >
                                {invoice?.code}
                              </Typography>
                              <Chip
                                label={
                                  invoiceChipStatusColors(invoice?.status).label
                                }
                                sx={{
                                  height: 20,
                                  fontSize: 12,
                                  fontWeight: 400,
                                  color: invoiceChipStatusColors(
                                    invoice?.status
                                  ).color,
                                  backgroundColor: invoiceChipStatusColors(
                                    invoice?.status
                                  ).backgroundColor,
                                  borderRadius: '20px'
                                }}
                              />
                            </Stack>
                          </Stack>
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            color="dark.main"
                          >
                            {numberWithCommas(invoice?.balance, 2, currency)}
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ borderRadius: '20px' }}>
                <CardHeader title={<Title title={dictionary.indexation} />} />
                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                  {data?.is_indexed ? (
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                    >
                      <Typography
                        variant="h6"
                        color="dark.main"
                        data-testid="Typography-fe2f7dad-dbfc-4665-847f-e269349cf73f"
                      >
                        {dictionary.source} :{' '}
                        <span
                          style={{
                            fontWeight: 600,
                            color: theme.palette.primary.main
                          }}
                        >
                          {data.index_type === 2
                            ? dictionary.ICC
                            : data.index_type === 3
                            ? dictionary.ILC
                            : dictionary.ILAT}
                        </span>
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="space-between"
                        width="70%"
                      >
                        <Stack>
                          <Typography
                            variant="caption"
                            color="text.light"
                            data-testid="Typography-0b59664e-e147-4938-8e27-e5269b2a2a90"
                          >
                            {dictionary.year}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="primary.main"
                            data-testid="Typography-0b59664e-e147-4938-8e27-e5269b2a2a90"
                          >
                            {data?.index_base_year}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography
                            variant="caption"
                            color="text.light"
                            data-testid="Typography-99049435-5c74-40ac-831c-3d398fcbe40f"
                          >
                            {dictionary.base_trimester}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="primary.main"
                            data-testid="Typography-99049435-5c74-40ac-831c-3d398fcbe40f"
                          >
                            {data?.index_base_trimester} -{' '}
                            {data?.index_base_trimester === 1
                              ? dictionary.jan
                              : data?.index_base_trimester === 2
                              ? dictionary.apr
                              : data?.index_base_trimester === 3
                              ? dictionary.jul
                              : dictionary.oct}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  ) : (
                    <Typography
                      variant="h6"
                      color="dark.main"
                      data-testid="Typography-90dca747-41b4-478d-9115-0ec61f878514"
                    >
                      {dictionary.not_indexed}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
            >
              <Typography variant="caption" color="text.light">
                Notes
              </Typography>
              <Grid
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'flex-end'
                }}
              >
                <TextField
                  // {...field}
                  fullWidth
                  multiline
                  minRows={6}
                  variant="filled"
                  label={dictionary.add_note}
                  value={description}
                  InputProps={{
                    style: {
                      paddingTop: 20,
                      paddingBottom: 8,
                      paddingX: 8,
                      backgroundColor: '#F2F3F4',
                      color: '#A3A5AB',
                      borderColor: '#E0E3E8'
                    }
                  }}
                  onChange={e => {
                    setDescription(e.target.value)
                    setChangeDesc(true)
                  }}
                />
                {changeDesc && (
                  <Button onClick={handleDescriptionClick}>
                    {dictionary.save}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <LeaseForm
        open={editLeaseModal.open}
        title={`${dictionary.edit} ${dictionary.lease}`}
        onClose={() => setEditLeaseModal(false)}
        operationId={operationId}
        leaseId={editLeaseModal.id}
        fetchLeasesURL={fetchURL}
        isEdit={true}
        // handleOpenLeaseDetails={onOpenLeaseDetails}
        handleClose={() => setEditLeaseModal(false)}
        currency={currency}
      />

      <DeleteModal
        open={deleteModal.open}
        onClose={() => setDeleteModal({ open: false, id: null })}
        onConfirm={() => onConfirmDelete()}
        description={dictionary.confirm_action_delete_lease}
        loading={loading}
      />
    </>
  )
}
