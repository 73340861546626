import React, { useState } from 'react'

import { useTranslation } from '@contexts/translation'
import { Controller } from 'react-hook-form'

import { Grid, MenuItem, Stack, TextField, Typography } from '@mui/material'

export default function IndexationStep(props) {
  const { control, errors, setValue, indexationFields } = props
  const { dictionary, t } = useTranslation()

  const currentYear = new Date().getFullYear()
  const yearTrimesters = Array.from({ length: 10 }, (_, index) => {
    const year = currentYear - index
    const trimesters = Array.from({ length: 4 }, (_, trimesterIndex) => {
      const trimester = trimesterIndex + 1
      return `${year} - ${t('trimester')} ${trimester}`
    })
    return trimesters
  }).flat()

  const [selectedIndexBase, setSelectedIndexBase] = useState(
    `${indexationFields[2]} - ${t('trimester')} ${indexationFields[3]}`
  )

  const handleIndexBaseChange = event => {
    const value = event.target.value
    setSelectedIndexBase(value)
    const index_base_year = value.split(' - ')[0]
    const index_base_trimester = value.split(' - ')[1].split(' ')[1]
    setValue('index_base_trimester', parseInt(index_base_trimester))
    setValue('index_base_year', parseInt(index_base_year))
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="dark.main">
            {dictionary.indexation}
          </Typography>
          <Typography fontSize={13} mt={1}>
            {dictionary.lease_indexation_desc}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          {/* {indexation && (
            <> */}
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">{dictionary.index_type}</Typography>
              <Controller
                control={control}
                name="index_type"
                defaultValue={1}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    variant="filled"
                    // label={dictionary.index_type}
                    control={control}
                    error={Boolean(errors.index_type)}
                    errorMessage={
                      errors.index_type && errors.index_type.message
                    }
                    InputProps={{
                      style: {
                        height: '44px'
                      }
                    }}
                    sx={{
                      '& .MuiInputBase-input': {
                        paddingY: 0,
                        marginY: 0,
                        height: '100%'
                      }
                    }}
                  >
                    <MenuItem
                      value={1}
                      data-testid="MenuItem-14c77ca9-0d94-49bc-8940-41d6a7e468bd"
                    >
                      {dictionary.ILAT}
                    </MenuItem>
                    <MenuItem
                      value={2}
                      data-testid="MenuItem-cb919cd9-9c7b-43c9-8eeb-26fb65e2fb20"
                    >
                      {dictionary.ICC}
                    </MenuItem>
                    <MenuItem
                      value={3}
                      data-testid="MenuItem-cb919cd9-9c7b-43c9-8eeb-26fb65e2fb20"
                    >
                      {dictionary.ILC}
                    </MenuItem>
                    <MenuItem value={4}>
                      {t('projects_leases_create_indexation_percentage')}
                    </MenuItem>
                  </TextField>
                )}
              />
            </Stack>
          </Grid>
          {indexationFields?.[1] === 4 && (
            <Grid item xs={12}>
              <Stack gap={1}>
                <Typography variant="caption">
                  {dictionary.percentage}
                </Typography>
                <Controller
                  control={control}
                  name="index_rate"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      type="number"
                      label={dictionary.index_rate}
                      error={Boolean(errors.index_rate)}
                      errorMessage={
                        errors.index_rate && errors.index_rate.message
                      }
                      InputProps={{
                        style: {
                          height: '44px'
                        }
                      }}
                      sx={{
                        '& .MuiInputBase-input': {
                          paddingY: 0,
                          marginY: 0,
                          height: '100%'
                        }
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">
                {t('project_leases_lease_index_base')}
              </Typography>
              <TextField
                fullWidth
                select
                disabled={indexationFields?.[1] === 4}
                variant="filled"
                value={selectedIndexBase}
                InputProps={{
                  style: {
                    height: '44px'
                  }
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    paddingY: 0,
                    marginY: 0,
                    height: '100%'
                  }
                }}
                onChange={handleIndexBaseChange}
              >
                {yearTrimesters?.map((yearTrimester, idx) => (
                  <MenuItem key={idx} value={yearTrimester}>
                    {yearTrimester}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Grid>
          {/* <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">
                {dictionary.index_base_trimester}
              </Typography>
              <Controller
                control={control}
                name="index_base_trimester"
                defaultValue={getCurrentTrimester()}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    variant="filled"
                    // label={dictionary.index_base_trimester}
                    control={control}
                    error={Boolean(errors.index_base_trimester)}
                    errorMessage={
                      errors.index_base_trimester &&
                      errors.index_base_trimester.message
                    }
                    InputProps={{
                      style: {
                        height: '44px',
                        paddingBottom: '15px',
                        overflow: 'hidden'
                      }
                    }}
                  >
                    <MenuItem
                      value={1}
                      data-testid="MenuItem-92c44ba2-7619-4078-a723-9395ea979281"
                    >
                      1
                    </MenuItem>
                    <MenuItem
                      value={2}
                      data-testid="MenuItem-b06fdeec-1b70-4b34-8c64-ae03f68d83a3"
                    >
                      2
                    </MenuItem>
                    <MenuItem
                      value={3}
                      data-testid="MenuItem-1f29d4ec-d55d-47e3-b0fe-b1fbb88db7c7"
                    >
                      3
                    </MenuItem>
                    <MenuItem
                      value={4}
                      data-testid="MenuItem-a7901c25-4fae-46ff-a817-44ed6c72ae99"
                    >
                      4
                    </MenuItem>
                  </TextField>
                )}
              />
            </Stack>
          </Grid> */}
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">{dictionary.period}</Typography>
              <Controller
                control={control}
                name="period"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    disabled
                    variant="filled"
                    label="1 an"
                    control={control}
                    error={Boolean(errors.period)}
                    errorMessage={errors.period && errors.period.message}
                    InputProps={{
                      style: { height: '44px', paddingBottom: '15px' }
                    }}
                  />
                )}
              />
            </Stack>
          </Grid>
          {/* </>
          )} */}
        </Grid>
      </Grid>
    </>
  )
}
